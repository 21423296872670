<template>
  <div class="headers">
    <div class="header-serarch">
      <van-search v-model="value" show-action label="晋中市" shape="round" background="#1281E0" placeholder="请输入搜索关键词" >
        <template #action>
          <div class="tzc_search" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "foodHeader",
  setup() {
    const state = reactive({
      value1: 0,
      value2: "a",
    });
    return {
      state,
    };
  },
});
</script>

<style scoped lang="less">
.headers {
  width: 100%;
  display: flex;
  background: #1281E0;
  .header-tree {
    width: 25%;
  }
  .header-serarch {
    width: 100%;
  }
  .tzc_search{
    color: white;
  }
}
/deep/ .van-dropdown-menu__bar {
  position: relative;
  display: flex;
  box-shadow: 0px 0px 0px #ffffff !important;
  background-color: #1281E0;
}
/deep/ .van-dropdown-menu__title {
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: bold;
}
/deep/ .van-dropdown-menu__title::after {
  position: absolute;
  top: 50%;
  right: -4px;
  margin-top: -5px;
  border: 3px solid;
  border-color: transparent transparent #ffffff #ffffff !important;
  transform: rotate(-45deg);
  opacity: 0.8;
  content: "";
}
/deep/ .van-dropdown-item__option--active {
    color: #1281E0;
}
/deep/ .van-dropdown-item__option--active .van-dropdown-item__icon {
    color: #1281E0;
}
</style>